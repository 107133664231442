// extracted by mini-css-extract-plugin
export var bottomSmall = "Conf-module--bottomSmall--eUXaG";
export var containerConf = "Conf-module--containerConf--l01o5";
export var flagIcon = "Conf-module--flagIcon--MDlqQ";
export var grid = "Conf-module--grid--Q+K2k";
export var gridContainer = "Conf-module--gridContainer--LGj+5";
export var gridItem = "Conf-module--gridItem--c2ivM";
export var item = "Conf-module--item--+O2ci";
export var materialIcons = "Conf-module--materialIcons--lGrEs";
export var mqBig = "Conf-module--mqBig--B2o8x";
export var mqSmall = "Conf-module--mqSmall--NxBAC";
export var number = "Conf-module--number--YyeF6";
export var numbers = "Conf-module--numbers--QUAqI";
export var topSmall = "Conf-module--topSmall--jOD64";