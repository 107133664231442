import React from 'react'
import { FormattedMessage } from 'react-intl'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'

//Components
import Layout from '../components/layout'
import Search from '../components/search'

//Styles
import * as conf from '../styles/Conf.module.scss'
import * as hero from '../styles/Hero.module.scss'
import '../../node_modules/flag-icon-css/sass/flag-icon.scss'

const Conference = ({ pageContext: { locale }, data, location }) => {
  const queryUrl = location.search
  const checkUrl = queryUrl ? queryUrl : ''
  return (
    <Layout locale={locale}>
      <div className={hero.heroImg}>
        <GatsbyImage
          alt=""
          className={hero.fixedHeight}
          image={data.file.childImageSharp.gatsbyImageData}
        />
        <div className={hero.searchField}>
          <h1>
            <FormattedMessage id="conf" />
          </h1>
          <h2>
            <FormattedMessage id="callingfrom" />
          </h2>
          <Search />
        </div>
      </div>
      <div className={conf.gridContainer}>
        <div className={`${conf.grid} ${conf.numbers}`}>
          {data.allDatoCmsCountry.edges.map(post => {
            const country_code = `flag-icon flag-icon-${post.node.countryCode}`
            const path = post.node.path
            const queryPath = path + checkUrl
            if (post.node.countryCode === "it") {
              post.node.number = "+39 073 3193 1511"
            }
            return (
              <div
                className={conf.gridItem}
                key={post.node.id}
                data-title={post.node.name}
              >
                <Link ass="ass" to={`/${locale}/conference/${queryPath}`}>
                  <h5>
                    {post.node.name}
                    <span className={country_code}> </span>
                  </h5>
                  <span>{post.node.number}</span>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

Conference.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default Conference

export const query = graphql`
  query indexQuery($locale: String!) {
    allDatoCmsCountry(
      filter: { locale: { eq: $locale } }
      sort: { order: ASC, fields: [name] }
    ) {
      edges {
        node {
          id
          path
          locale
          name
          number
          countryCode
        }
      }
    }
    file(relativePath: { eq: "background-conf.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, height: 400, placeholder: BLURRED)
      }
    }
  }
`
