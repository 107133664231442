import React from 'react'
import { injectIntl } from 'react-intl'
import * as hero from '../styles/Hero.module.scss'

class Search extends React.Component {
  constructor() {
    super()
    this.state = {
      search: '',
    }
  }

  updateSearch = event => {
    this.setState({ search: event.target.value.substr(0, 10) })
    const dataTitles = document.querySelectorAll('[data-title]')
    var arrTitles = Array.prototype.slice.call(dataTitles)
    arrTitles.forEach(data => {
      var dataAtt = data.getAttribute('data-title')
      let title = dataAtt.toLowerCase().toString()
      let targetLowerCase = event.target.value.toLowerCase()
      if (title.indexOf(targetLowerCase) === -1) {
        data.style = 'display: none'
      }
      if (title.indexOf(targetLowerCase) !== -1) {
        data.style = 'display: flex'
      }
    })
  }

  handlePlaceholder = () => {
    const { intl } = this.props
    return intl.formatMessage({ id: 'search' })
  }
  render() {
    return (
      <div>
        <span className={`material-icons ${hero.materialIconsSearch}`}>
          search
        </span>
        <input
          type="text"
          placeholder={this.handlePlaceholder()}
          value={this.state.search}
          onChange={this.updateSearch}
        />
      </div>
    )
  }
}

export default injectIntl(Search)
